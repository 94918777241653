import { useCorptaxTheme } from '@corptax/react-components-common';
import { DefaultButton, mergeStyleSets, Stack, Text } from '@fluentui/react';
import { PropsWithChildren } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';
import Icon from './Icon';

export interface IFilterGroupProps {
    isFiltered: boolean;
    clearAllFiltersLabel: string;
    clearAllFilters: () => void;
    containerClassname?: string;
    dataTestid?: string;
}

const FilterGroup: React.FC<PropsWithChildren<IFilterGroupProps>> = ({
    children,
    isFiltered,
    containerClassname,
    clearAllFiltersLabel,
    clearAllFilters,
    dataTestid,
}) => {
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const getFilterBarStyles = () =>
        mergeStyleSets({
            buttonStyle: {
                boxSizing: 'border-box',
                width: '163px',
                background: palette.white,
                border: '1px solid',
                borderColor: palette.neutralLight,
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02)',
                borderRadius: '6px',
                order: 1,
                marginLeft: '10px',
                padding: 0,
            },
            cancelButtonText: {
                height: '22px',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '22px',
                color: customPalette.blueDark,
                order: 2,
                flexGrow: 0,
                margin: '10px',
            },
            cancelButtonIcon: {
                height: '12px',
                width: '12px',
                order: 1,
            },
        });

    const { buttonStyle, cancelButtonIcon, cancelButtonText } = getFilterBarStyles();

    return (
        <Stack horizontal wrap data-testid={dataTestid} className={containerClassname}>
            {children}
            {isFiltered && (
                <DefaultButton className={buttonStyle} onClick={clearAllFilters}>
                    <Icon iconName='Dismiss20Regular' className={cancelButtonIcon} />
                    <Text className={cancelButtonText}>{clearAllFiltersLabel}</Text>
                </DefaultButton>
            )}
        </Stack>
    );
};

export default FilterGroup;
