import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { mergeStyleSets } from '@fluentui/react';
import { useMemo } from 'react';
import { IAppTheme } from '../theme/IAppTheme';

export const useCommonIndividualReportTableHeaderStyles = () => {
    const { palette, customPalette, brandPalette } = useCorptaxTheme<IAppTheme>();
    const commonIndividualReporHeaderStyles = useMemo(() => {
        return mergeStyleSets({
            cardStyles: {
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
                padding: '12px 16px 0px',
                margin: '0 0 15px 0',
                boxSizing: 'border-box',
                backgroundColor: palette.white,
                gap: '5px',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                boxShadow: '0px 1.6px 0px 0px #00000021',
            },
            textStyles: {
                fontSize: '9px',
                fontWeight: 500,
                lineHeight: '12px',
                letterSpacing: '2.4px',
                textAlign: 'left',
                width: '150px',
                height: '12px',
                color: customPalette.blueDark,
            },
            currentReportNameTextStyles: {
                fontSize: '20px',
                fontWeight: 800,
                lineHeight: '28px',
                letterSpacing: '0.01em',
                textAlign: 'left',
                color: customPalette.blueDark,
            },
            iconWrapper: {
                color: customPalette.themePrimary,
            },
            icon: {
                height: '24px',
                width: '24px',
                color: customPalette.grey222222,
                paddingTop: '5px',
            },
            iconButton: {
                ':disabled': {
                    backgroundColor: 'transparent',
                },
                ':disabled svg': {
                    color: corptaxCustomLightTheme.colorNeutralStrokeDisabled,
                },
            },
            statusText: {
                color: customPalette.blueDark,
            },
            separatorStyles: {
                height: '32px',
                '::after': {
                    backgroundColor: brandPalette.lightGrayColor.hex,
                },
            },
            topSection: {
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                width: '100%',
                justifyContent: 'space-between',
            },
            buttonBar: {
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
            },
            buttonBarWrapper: {
                display: 'flex',
                alignSelf: 'stretch',
                gap: '5px',
            },
        });
    }, [customPalette.blueDark, customPalette.themePrimary, palette.white, brandPalette.lightGrayColor.hex]);
    return commonIndividualReporHeaderStyles;
};
