import { mergeStyles } from '@fluentui/react';
import { Inject } from '@syncfusion/ej2-react-base';
import { Navigation, PageRenderCompleteEventArgs, PdfViewerComponent, Toolbar } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import LoadingSpinner from '../../common/LoadingSpinner';

interface IPdfViewerFormProps {
    pdfData: string | null;
    isLoading?: boolean;
    showPdf?: boolean;
    onPageChange: (page: any) => void;
}

const DefaultPDFViewer: React.FC<IPdfViewerFormProps> = ({ pdfData, isLoading = false, showPdf = false, onPageChange = () => {} }) => {
    const pdfViewer = useRef<PdfViewerComponent>(null);
    const componentRef = useRef(null);
    const { currentPage } = useFormPdfView();

    const { t } = useTranslation();

    const pdfViewerClassName = mergeStyles({
        height: '100%',
        width: '100%',
        display: showPdf && !isLoading ? 'flex' : 'none',
    });

    const pdfContainerClass = mergeStyles({
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: showPdf || isLoading ? 'flex' : 'none',
    });

    const goToPage = (pageNumber: number | null) => {
        if (pageNumber !== null) {
            pdfViewer?.current?.navigation.goToPage(pageNumber + 1);
        }
    };

    const scrollToSelectedPageIndex = (args: PageRenderCompleteEventArgs) => {
        if (args.data.pageNumber === 1 && currentPage.pageIndex && currentPage.pageIndex > args.data.pageNumber - 1) {
            goToPage(currentPage.pageIndex);
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (pdfViewer.current && pdfData) {
                pdfViewer.current.updateViewerContainer();
            }
        });

        if (componentRef.current && pdfData) {
            resizeObserver.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                resizeObserver.unobserve(componentRef.current);
            }
        };
    }, [pdfData]);

    useEffect(() => {
        if (pdfViewer.current && pdfData && isLoading) {
            pdfViewer.current.unload();
        }
    }, [isLoading, pdfViewer, pdfData]);

    useEffect(() => {
        if (currentPage.pageIndex != null) goToPage(currentPage.pageIndex);
    }, [currentPage.pageIndex]);

    return (
        <div className={`pdf-container ${pdfContainerClass}`} ref={componentRef}>
            <PdfViewerComponent
                ref={pdfViewer}
                id='formViewerPDF'
                documentPath={pdfData || ''}
                enableToolbar={false}
                resourceUrl={`${origin}/ej2-pdfviewer-lib`}
                enableNavigationToolbar={false}
                enableNavigation={true}
                className={pdfViewerClassName}
                enableThumbnail={false}
                pageRenderComplete={scrollToSelectedPageIndex}
                pageChange={onPageChange}
            >
                <Inject services={[Toolbar, Navigation]} />
            </PdfViewerComponent>
            {isLoading ? <LoadingSpinner id='loadingViewFormSpinner' label={t('loadingPleaseWait').toString()} /> : null}
        </div>
    );
};

export default DefaultPDFViewer;
