import { useCorptaxTheme } from '@corptax/react-components-common';
import {
    DefaultButton,
    DirectionalHint,
    IconButton,
    ITooltipHostProps,
    mergeStyles,
    Separator,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { EmitType } from '@syncfusion/ej2-base';
import { DataManager } from '@syncfusion/ej2-data';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-grids';
import {
    ColumnModel,
    ContextMenu,
    ContextMenuItemModel,
    Edit,
    EditEventArgs,
    EditSettingsModel,
    Freeze,
    GridActionEventArgs,
    IIndex,
    Inject,
    LoadingIndicatorModel,
    Resize,
    RowSelectEventArgs,
    SearchSettingsModel,
    VirtualScroll,
} from '@syncfusion/ej2-react-grids';
import { MenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCancelScheduledCalculation, useGetTaxReturnById, useSetTaxReturnStatus } from '../../../api/report/report';
import { CommentsUIStateProvider } from '../../../contexts/commentsUIStateContext';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { useUserSettings } from '../../../contexts/useUserSettings';
import { ISelectedTaxReturnsAndFilters } from '../../../data-types/exportReports';
import { IFilterItem } from '../../../data-types/filterItem';
import { IContextMenuArgs } from '../../../data-types/IContextMenuArgs';
import { ICurrentCellEventArgs } from '../../../data-types/ICurrentCellEventArgs';
import { ISelectableGrid } from '../../../data-types/ISelectableGrid';
import {
    ContextMenuVisibilityAccessor,
    useCommonIndividualReportTableContextMenu,
} from '../../../hooks/useCommonIndividualReportTableContextMenu';
import { useCommonIndividualReportTableHeaderStyles } from '../../../hooks/useCommonIndividualReportTableHeaderStyles';
import { useEditableTable } from '../../../hooks/useEditableTable';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import { useFunctionalAccess } from '../../../hooks/useFunctionalAccess';
import { useGridScroll } from '../../../hooks/useGridScroll';
import { useGridState } from '../../../hooks/useGridState';
import { useHighlightSearchedValues } from '../../../hooks/useHighlightSearchedValues';
import { useIsCommentsSubroute } from '../../../hooks/useIsCommentsSubroute';
import { useReportColumnsAsJsx } from '../../../hooks/useReportColumnsAsJsx';
import { useSearchTable } from '../../../hooks/useSearchTable';
import { useSelectedEntities } from '../../../hooks/useSelectedEntities';
import { useTaxReturnCalculationState } from '../../../hooks/useTaxReturnCalculationState';
import {
    CalculationExecutionStatusCode,
    IWhitePaperComparedValue,
    IWhitePaperComparedValueDictionary,
    IWhitePaperReport,
    IWhitePaperRow,
    ReportStatus,
} from '../../../model';
import { initialUIState } from '../../../reducers/commentsStateReducer';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getTooltipHostProps } from '../../../utils/CustomStyles';
import { getEntitiesFilterForColumns } from '../../../utils/FilterUtils';
import {
    formatEditableRowValues,
    getCellHighlightingClasses,
    getCellValueByFieldName,
    getDynamicFieldNameParts,
    isNegativeValue,
    markModifiedValues,
    parseRowValuesToNumber,
    shouldReportBeOnTopOfRecentlyOpened,
    updateEmptyRowTextInGrid,
} from '../../../utils/ReportUtils';
import {
    clickOnVarianceContainedByCell,
    getClassNameForStyle,
    getFirstFocusableElementInForm,
    getTableKeyWithVisibleFields,
    scrollToTop,
    setFocusOnSyncfusionGridCell,
    shouldUpdateAriaLabel,
} from '../../../utils/TableUtils';
import { REPORTS_TABLE_URL } from '../../../utils/Urls.constant';
import DefaultTooltipHost from '../../common/DefaultTooltipHost';
import ExportReportButton from '../../common/ExportReportButton';
import FunctionalAccessDisableWrapper from '../../common/FunctionalAccessDisableWrapper';
import Icon from '../../common/Icon';
import LoadingSpinner from '../../common/LoadingSpinner';
import { ReportStatusButton } from '../../common/ReportStatusButton';
import { CommentsPanel, CommentsPanelLocationType } from '../../sidebar/CommentsPanel';
import ReportToolbar from '../../toolbar/ReportToolbar';
import { CalculationOptionsPanel } from './CalculationOptionsPanel';
import CommonIndividualReportHeader from './CommonIndividualReportHeader';
import PdfViewerForm from './PDFViewerForm';
import { contextMenuIconStyles, StyledIndividualReportGrid } from './StyledIndividualReportGrid';

export interface ICommonIndividualReportTableContextMenuItemProps<T> {
    getVisibilityState: ContextMenuVisibilityAccessor;
    onMenuItemClick?: (args?: ICurrentCellEventArgs<T> | null) => void;
}

export interface ICommonIndividualReportTableContextMenuProps {
    editCell: ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>;
    enterAmounts: ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>;
    drillDown: ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>;
    comments: ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>;
}

export interface ICommonIndividualReportTableProps {
    currentReport?: IWhitePaperReport;
    isError: boolean;
    isTableDataReadyToDisplay: boolean;
    columns: ColumnModel[];
    componentRef?: React.MutableRefObject<ISelectableGrid | null>;
    dataManager?: DataManager;
    currentCellArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null;
    setCurrentCellArgs?: (value: ICurrentCellEventArgs<IWhitePaperRow> | null) => void;
    contextMenuProps?: ICommonIndividualReportTableContextMenuProps;
    isRecalculateNeeded: boolean;
    setIsRecalculateNeeded: (isRecalculateNeeded: boolean) => void;
    reportId: number;
    versionId?: string;
    setIsDirtyFlag?: (isDirty: boolean) => void;
    calculateButton?: React.ReactNode;
    compareButton?: JSX.Element;
    actionBar?: React.ReactNode;
    onRowSelected?: EmitType<RowSelectEventArgs>;
    filterGroupItems: React.ReactNode[];
    clearAllFilters: () => void;
    shouldDisplayCalculationOptions: boolean;
    loadingIndicator?: LoadingIndicatorModel;
    onRenderFinished?: () => void;
    onScrollingStarted?: () => void;
}

export const descriptionTemplate = (item: IWhitePaperRow) => {
    let classes = '';
    if (item.styles && item.styles.length > 0) {
        classes = item.styles.map((style) => getClassNameForStyle(style)).join(' ');
    }

    return (
        <DefaultTooltipHost tooltipId={item.rowNumber?.toString()} tooltipContent={item.description?.toString()}>
            <span tabIndex={-1} className={classes}>
                {item.description}
            </span>
        </DefaultTooltipHost>
    );
};

const CommonIndividualReportTable = (props: ICommonIndividualReportTableProps) => {
    const { currentReport, isError, isTableDataReadyToDisplay, columns, componentRef, dataManager, currentCellArgs, setCurrentCellArgs } =
        props;
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { showPdf } = useFormPdfView();
    const { activeMessage } = useFeedbackMessages();
    const { functionalAccess } = useFunctionalAccess();
    const messageHeight = activeMessage ? '32px' : '0px';

    const wrapperStyles = mergeStyles({
        displayName: 'tableGridWrapper',
        height: `calc(100vh - 215px - ${messageHeight})`,
        '@media screen and (max-width: 1300px)': {
            height: `calc(100vh - 245px - ${messageHeight})`,
        },
        margin: '0px 15px',
    });
    const { separatorStyles } = useCommonIndividualReportTableHeaderStyles();
    const scrollablePaneWrapperStyles = mergeStyles({
        displayName: 'gridWrapper',
        background: customPalette.themeLighterAlt,
        border: `1px solid ${customPalette.greyD1D3D4}`,
        borderTop: 'none',
        boxShadow: 'none',
        overflow: 'auto',
        '::-webkit-scrollbar': {
            height: '35px',
        },
    });

    const { getFilteredEntities } = useSelectedEntities();
    const { applySearch, searchValue, isSearchValueApplied } = useSearchTable();

    const [currentlySelectedCell, setCurrentlySelectedCell] = useState<HTMLElement | undefined>(undefined);
    const { grid: gridRef } = useGridState();
    const isCommentsSubroute = useIsCommentsSubroute();
    //if this is a comments subroute, start with the comments panel open
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(isCommentsSubroute);
    const [isCalculationOptionsPanelVisible, { setTrue: showCalculationOptionsPanel, setFalse: hideCalculationOptionsPanel }] =
        useBoolean(false);

    const { data: currentReportInformation } = useGetTaxReturnById(props.reportId);

    const entities: IFilterItem[] = getEntitiesFilterForColumns(currentReport?.columns || []);
    const filteredEntities: IFilterItem[] = getFilteredEntities(entities);

    //Use custom hook to separate the JSX element creation for columns
    const { columnsAsJsx, columnsToSearch } = useReportColumnsAsJsx(columns, filteredEntities);
    const { focusCellAfterEventCompleted, formatFieldValues, highlightCell } = useEditableTable<IWhitePaperRow>({
        parseRowValuesToNumber: (row) => parseRowValuesToNumber(row!),
        getCellHighlightingClasses,
        formatEditableRowValues,
        markModifiedValues,
        setGridDirtyFlag: props.setIsDirtyFlag,
        formElementToFocusOnEditBegin: (args: EditEventArgs) => getFirstFocusableElementInForm(args!.form!),
    });
    const { mostRecentlyOpenedTabs, updateReturnTabs } = useUserSettings();
    const navigate = useNavigate();
    const {
        currentRowIndex,
        currentColumnIndex,
        shouldScrollToRowOnDataBound,
        shouldScrollToColumnOnDataBound,
        setShouldScrollOnDataBoundFalse,
        setShouldScrollToColumnOnDataBoundFalse,
    } = useGridScroll();
    const { isReportExecuting, updateTaxReturnProcessingStatus, getReportStatus, updateTaxReturnCalculationReport } =
        useTaxReturnCalculationState();
    const reportStatus = getReportStatus(props.reportId);

    const { mutate: cancelScheduledCalculation } = useCancelScheduledCalculation({
        mutation: {
            onSuccess: (wasCanceled: any) => {
                if (wasCanceled) {
                    updateTaxReturnCalculationReport({
                        taxReturnItemKey: props.reportId,
                        jobId: reportStatus?.jobId ?? 0,
                        statusCode: CalculationExecutionStatusCode.Canceled,
                    });

                    if (!currentReportInformation?.executed) {
                        navigate(REPORTS_TABLE_URL);
                    }
                }
            },
        },
    });

    const { menuItemOptions: menuItems, updateMenuItemsUsability } = useCommonIndividualReportTableContextMenu(props.contextMenuProps);

    const contextMenuItemClassName = mergeStyles(contextMenuIconStyles);
    const contextMenuItems: Array<ContextMenuItemModel> = menuItems.map((menuItem) => menuItem.contextMenuItem);

    const { storeSearchValueToHighlight, highlightSearchValueOnRender } = useHighlightSearchedValues(getCellValueByFieldName);
    const tableKey = getTableKeyWithVisibleFields(columnsToSearch, props.versionId ?? '');
    const isLargeData = useMemo(() => columns.length > 25, [columns.length]);
    const hasReportOptionsAccess = functionalAccess?.reportOptionsButtonEnabled;

    useEffect(() => {
        if (gridRef && gridRef.current && searchValue !== '') {
            applySearch(gridRef);
        }
    }, [dataManager]);

    useEffect(() => {
        const isInTaxReturnCalculationStatusList = isReportExecuting(props.reportId);
        if (props.reportId && isTableDataReadyToDisplay && isInTaxReturnCalculationStatusList) {
            updateTaxReturnProcessingStatus(props.reportId);
        }
    }, [props.reportId, isTableDataReadyToDisplay]);

    useEffect(() => {
        if (shouldReportBeOnTopOfRecentlyOpened(currentReportInformation, mostRecentlyOpenedTabs)) {
            updateReturnTabs(
                {
                    returnKey: Number(currentReportInformation?.id),
                    returnName: currentReportInformation?.name ?? '',
                    statusCode:
                        mostRecentlyOpenedTabs?.filter((item) => item.returnKey === Number(currentReportInformation?.id))[0]?.statusCode ||
                        CalculationExecutionStatusCode.Unknown,
                },
                true
            );
        }
    }, [currentReportInformation]);

    const handleContextMenuItemClick = (args: MenuEventArgs) => {
        // iterate over the collection of menu items and call its click handler.
        // if it returns true, break out of the loop, else keep going
        for (const menuItem of menuItems) {
            // to avoid magic strings, each click handler checks that the args id matches and return false if not
            // so we invoke each in turn, because god forbid syncfusion has click handlers for each item, no, it's for the entire context menu like animals
            if (menuItem!.handleContextMenuItemClick(args, currentCellArgs)) {
                break;
            }
        }
    };

    if (componentRef) {
        componentRef.current = {
            focusCell: async (_: IIndex) => {
                setFocusOnSyncfusionGridCell(currentlySelectedCell);
            },
            startEdit: async (index: IIndex) => {
                const clickedRow = gridRef.current
                    ?.getRows()
                    .find((row) => row.getAttribute('data-rowindex') === index.rowIndex?.toString());

                if (clickedRow) {
                    gridRef.current?.editModule.startEdit(clickedRow as HTMLTableRowElement);
                }
            },
            markAsEdited: async (index: IIndex) => {
                const gridDataManager: DataManager = gridRef.current!.dataSource as DataManager;
                const dataManagerJsonArray: Object[] = gridDataManager.dataSource.json!;
                const row = dataManagerJsonArray[index.rowIndex!] as IWhitePaperRow;
                const values: IWhitePaperComparedValueDictionary = row.values!;
                const cellIndex: number = index.cellIndex!;
                const { entityCode } = getDynamicFieldNameParts(columns[cellIndex].field!);
                const comparedValue: IWhitePaperComparedValue = values[entityCode]!;
                comparedValue.isChangedByDrilldown = true;
                // refreshes the entire grid, which might be overkill, but it triggers the cell highlighting callback we define in the parent component
                gridRef.current!.refresh();
            },
        };
    }

    const getClickedTdElement = (target: HTMLElement): HTMLElement => {
        if (target.tagName !== 'td') {
            return target.closest('td') as HTMLElement;
        }

        return target;
    };

    const handleContextMenuOpen = (args: IContextMenuArgs<IWhitePaperRow>) => {
        const cellIndex: IIndex = {
            cellIndex: args.rowInfo.cellIndex,
            rowIndex: args.rowInfo.rowIndex,
        };

        if (gridRef && gridRef.current) {
            if (gridRef.current.isEdit) {
                args.cancel = true;
                return;
            }

            gridRef.current.contextMenuModule.contextMenu.onClose = () => {
                setFocusOnSyncfusionGridCell(args.rowInfo.cell);
            };
            updateMenuItemsUsability(args, gridRef.current);
        }

        if (setCurrentCellArgs && args.column) {
            setCurrentCellArgs({
                cellIndex: cellIndex,
                data: args.rowInfo.rowData as IWhitePaperRow,
                fieldName: args.column.field,
            });
        }

        setTimeout(() => {
            const clickedElement = getClickedTdElement(args.event.target as HTMLElement);
            if (clickedElement) {
                setCurrentlySelectedCell(clickedElement);
                clickedElement.classList.add('e-focused');
            }
        }, 400);
    };

    const handleCreated = () => {
        if (gridRef.current) {
            gridRef.current.contextMenuModule.contextMenu.cssClass += ` ${contextMenuItemClassName}`;
            props.setIsDirtyFlag && props.setIsDirtyFlag(false);
        }
    };

    const updateAriaLabelAndCustomizeCell = (args: QueryCellInfoEventArgs) => {
        highlightSearchValueOnRender(args);

        if (shouldUpdateAriaLabel(args)) {
            const cellValue = getCellValueByFieldName(args.data as IWhitePaperRow, args.column?.field ?? '');
            const isNegative = isNegativeValue(cellValue?.toString());

            if (args.cell && cellValue) {
                args.cell?.setAttribute('aria-label', `${isNegative ? t('minus') : ''}${cellValue}`);
            }
        }
        highlightCell(args);
    };

    const onDatabound = () => {
        if (shouldScrollToRowOnDataBound) {
            setShouldScrollOnDataBoundFalse();
            setTimeout(() => {
                if (gridRef.current) {
                    if (currentRowIndex !== null && currentRowIndex.current !== null) {
                        gridRef.current.selectRow(currentRowIndex.current);
                    }
                    currentRowIndex.current = null;
                }
            }, 250);
        }

        if (shouldScrollToColumnOnDataBound) {
            setShouldScrollToColumnOnDataBoundFalse();
            setTimeout(() => {
                if (gridRef.current) {
                    if (currentColumnIndex !== null && currentColumnIndex.current !== null) {
                        clickOnVarianceContainedByCell(gridRef.current, {
                            rowIndex: currentRowIndex.current ?? undefined,
                            cellIndex: currentColumnIndex.current ?? undefined,
                        });

                        currentColumnIndex.current = null;
                    }
                }
            }, 250);
        }

        if (gridRef && gridRef.current) {
            updateEmptyRowTextInGrid(gridRef.current, t('noRowsMeetTheCriteria'));
        }

        if (props.onRenderFinished) {
            props.onRenderFinished();
        }
    };

    const searchSettings: SearchSettingsModel = {
        fields: columnsToSearch,
        ignoreCase: true,
    };

    const editSettings: EditSettingsModel = {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: 'Normal',
        allowEditOnDblClick: false,
    };

    const handleActionComplete = (args: GridActionEventArgs) => {
        focusCellAfterEventCompleted(args);

        if (args.requestType === 'searching') {
            scrollToTop(gridRef.current);
        }
    };

    const handleActionBegin = (args: GridActionEventArgs) => {
        if (props.onScrollingStarted && args.requestType === 'virtualscroll') {
            props.onScrollingStarted();
        }
        formatFieldValues(args);
        storeSearchValueToHighlight(args);
    };

    const handleCancelCalculation = () => {
        if (reportStatus && reportStatus.jobId) cancelScheduledCalculation({ data: { jobId: reportStatus.jobId } });
    };

    const { mutateAsync: setTaxReturnStatus } = useSetTaxReturnStatus({
        mutation: {
            onSuccess: (data: any, { params }) => {
                if (data) {
                    if (currentReportInformation) {
                        currentReportInformation.status = params?.reportStatus;
                    }
                }
            },
        },
    });

    const handleStatusChange: (key: ReportStatus) => void = async (key: ReportStatus) => {
        try {
            //todo: processing indicator
            setTaxReturnStatus({ reportId: props.reportId, params: { reportStatus: key } });
            //todo: error indicator
        } catch (error: any) {
            if (error) {
                throw new Error(error?.request?.response);
            }
        }
    };
    const { currentReportNameTextStyles, statusText, icon, iconWrapper, iconButton } = useCommonIndividualReportTableHeaderStyles();

    const currentReportIsCalculating = isReportExecuting(props.reportId) || false;
    const shouldDisplayIsLoadingSpinner = currentReportIsCalculating || !isTableDataReadyToDisplay;
    const shouldDisableInteractiveElements = shouldDisplayIsLoadingSpinner;

    const commentId = useParams().commentId;
    const initialCommentsUIState = useMemo(() => {
        const highlightedCommentId = commentId ? commentId.toLowerCase() : null;
        return { ...initialUIState, highlightedCommentId };
    }, [commentId]);

    const getCurrentReportAsArray = (): ISelectedTaxReturnsAndFilters => {
        if (currentReportInformation) {
            const selectedTaxReturnsFilter: ISelectedTaxReturnsAndFilters = { selectedTaxReturns: [currentReportInformation] };
            return selectedTaxReturnsFilter;
        }

        return {};
    };

    const topContentLeft: React.ReactNode[] = [
        <Stack key='topContentLeft'>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
                <h2 className={currentReportNameTextStyles}>{currentReportInformation?.name}</h2>
                <Text className={statusText}>{t('status').toString()}:</Text>
                <Stack>
                    <ReportStatusButton
                        status={currentReportInformation?.status ?? ReportStatus.NotStarted}
                        onChange={handleStatusChange}
                        disabled={shouldDisableInteractiveElements}
                    />
                </Stack>
            </Stack>
        </Stack>,
    ];

    const tooltipCalculationOptionHostProps: Partial<ITooltipHostProps> = getTooltipHostProps(
        DirectionalHint.rightTopEdge,
        undefined,
        customPalette
    );
    tooltipCalculationOptionHostProps.content = t('calculationOptions').toString();
    tooltipCalculationOptionHostProps.id = 'calculationOptionsTooltip';

    const fallbackTableHeight = `${window.innerHeight - 415}px`;

    const calculationOptionsButton = (
        <IconButton
            className={iconButton}
            id={t('calculationOptions').toString()}
            ariaLabel={t('calculationOptions').toString()}
            onClick={showCalculationOptionsPanel}
            disabled={hasReportOptionsAccess === false}
        >
            <Stack className={iconWrapper}>
                <Icon iconName='TextBulletListSquareSettings20Regular' className={icon} />
            </Stack>
        </IconButton>
    );

    const topContentRight: React.ReactNode[] = [
        <Stack horizontal key='topContentRight' style={{ gap: 5 }}>
            <Stack>
                <CommentsUIStateProvider commentsUIState={initialCommentsUIState}>
                    <CommentsPanel
                        locationType={CommentsPanelLocationType.Report}
                        reportId={props.reportId.toString()}
                        reportName={currentReportInformation?.name ?? undefined}
                        isOpen={isCommentsPanelOpen}
                        onClose={() => setIsCommentsPanelOpen(false)}
                    />
                </CommentsUIStateProvider>
            </Stack>
            <IconButton
                ariaLabel='View Comments'
                className={iconButton}
                onClick={() => {
                    setIsCommentsPanelOpen(true);
                }}
                disabled={shouldDisableInteractiveElements}
            >
                <Stack className={iconWrapper}>
                    <Icon iconName='Comment20Regular' className={icon} />
                </Stack>
            </IconButton>
            {props.shouldDisplayCalculationOptions ? (
                <>
                    {hasReportOptionsAccess ? (
                        <TooltipHost {...tooltipCalculationOptionHostProps}>{calculationOptionsButton}</TooltipHost>
                    ) : (
                        <FunctionalAccessDisableWrapper hasFunctionalAccess={hasReportOptionsAccess}>
                            {calculationOptionsButton}
                        </FunctionalAccessDisableWrapper>
                    )}
                </>
            ) : null}
            <Separator className={separatorStyles} vertical />
            {props.calculateButton}
            <ExportReportButton getReportsToExport={getCurrentReportAsArray} disabled={shouldDisableInteractiveElements} />
        </Stack>,
    ];

    const isFiltered = isSearchValueApplied || filteredEntities.length > 0;

    return isError ? (
        <div>
            <label>{t('Error')}</label>
        </div>
    ) : (
        <Stack data-testid='commonIndividualReportTable' className={mergeStyles({ displayName: 'commonIndividualReportTable' })}>
            <CommonIndividualReportHeader
                topContentLeft={topContentLeft}
                topContentRight={topContentRight}
                actionBar={props.actionBar}
                isRecalculateNeeded={props.isRecalculateNeeded}
            />
            {shouldDisplayIsLoadingSpinner ? (
                <LoadingSpinner
                    id='loadingSpinner'
                    label={currentReportIsCalculating ? t('calculating').toString() : t('loadingPleaseWait').toString()}
                    labelPosition={currentReportIsCalculating ? 'bottom' : 'left'}
                >
                    {currentReportIsCalculating && (
                        <DefaultButton
                            text={t('stopRunning').toString()}
                            ariaLabel={t('stopRunning').toString()}
                            disabled={!reportStatus?.jobId}
                            onClick={handleCancelCalculation}
                        >
                            <Icon iconName='Dismiss20Regular' />
                        </DefaultButton>
                    )}
                </LoadingSpinner>
            ) : (
                <Stack className={wrapperStyles}>
                    <PdfViewerForm returnKey={currentReportInformation?.id} reportColumns={currentReport?.columns ?? []} />

                    {!showPdf ? (
                        <>
                            <ReportToolbar
                                filterGroupItems={props.filterGroupItems}
                                isFiltered={isFiltered}
                                clearAllFilters={props.clearAllFilters}
                                compareButton={props.compareButton}
                            />
                            <Stack.Item className={scrollablePaneWrapperStyles}>
                                <StyledIndividualReportGrid
                                    aria-label={'Report Details'}
                                    dataSource={dataManager}
                                    allowResizing={true}
                                    ref={gridRef}
                                    enableVirtualization={true}
                                    enableColumnVirtualization={isLargeData}
                                    enableVirtualMaskRow={true}
                                    pageSettings={{ pageSize: 40 }}
                                    rowHeight={36}
                                    height={fallbackTableHeight}
                                    contextMenuItems={contextMenuItems}
                                    contextMenuClick={handleContextMenuItemClick}
                                    contextMenuOpen={handleContextMenuOpen}
                                    queryCellInfo={updateAriaLabelAndCustomizeCell}
                                    created={handleCreated}
                                    allowTextWrap={false}
                                    dataBound={onDatabound}
                                    editSettings={editSettings}
                                    actionComplete={handleActionComplete}
                                    actionBegin={handleActionBegin}
                                    searchSettings={searchSettings}
                                    key={tableKey}
                                    rowSelected={props.onRowSelected}
                                    loadingIndicator={props.loadingIndicator}
                                >
                                    {columnsAsJsx}
                                    <Inject services={[Resize, VirtualScroll, Freeze, ContextMenu, Edit]} />
                                </StyledIndividualReportGrid>
                            </Stack.Item>
                        </>
                    ) : null}
                </Stack>
            )}
            {isCalculationOptionsPanelVisible && (
                <CalculationOptionsPanel
                    calculationOptions={currentReport?.options || []}
                    entityCode={currentReportInformation?.entityCode || ''}
                    isOpen={isCalculationOptionsPanelVisible}
                    columns={currentReport?.columns || []}
                    onClose={hideCalculationOptionsPanel}
                ></CalculationOptionsPanel>
            )}
        </Stack>
    );
};

export default CommonIndividualReportTable;
