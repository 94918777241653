import { t } from 'i18next';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useCallback, useState } from 'react';
import { useEmbedAnalyticsReport } from '../../api/analytics/analytics';
import { IAnalyticsEmbedReportDetails, IAnalyticsEmbedTokenDetails, IEmbedAnalyticsReportResponse, ProblemDetails } from '../../model';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import { ErrorType } from '../../utils/UseCustomInstance';
import LoadingSpinner from '../common/LoadingSpinner';

export interface IAnalyticsReportProperties {}

const getAnalyticsReportContainerStyleName: () => string = mergeStylesOnDemand({
    displayName: 'analytics-report-container',
    height: 'calc(100vh - 56px - 80px)',
    marginBottom: '20px',
});

const getAnalyticsReportContentStyleName: () => string = mergeStylesOnDemand({
    displayName: 'analytics-report-content',
    iframe: {
        border: 0,
    },
    height: 'calc(100% - 40px)',
});

const AnalyticsReport: React.FC<IAnalyticsReportProperties> = (props: IAnalyticsReportProperties) => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [errorMessage, setErrorMesssage] = useState<string | undefined>();
    const [embedAnalyticsReportResponse, setEmbedAnalyticsReportResponse] = useState<IEmbedAnalyticsReportResponse | undefined>();
    const { mutate: embedAnalyticsReport } = useEmbedAnalyticsReport();

    const renderAnalyticsReport: () => JSX.Element | null = useCallback(() => {
        if (embedAnalyticsReportResponse) {
            const embedReportDetails: IAnalyticsEmbedReportDetails = embedAnalyticsReportResponse!.analyticsReport!;
            const embedTokenDetails: IAnalyticsEmbedTokenDetails = embedAnalyticsReportResponse!.embedToken!;
            const displayReport: boolean = !(isLoading || errorMessage);

            return (
                <div style={{ display: displayReport ? undefined : 'none' }} className={getAnalyticsReportContainerStyleName()}>
                    <PowerBIEmbed
                        cssClassName={getAnalyticsReportContentStyleName()}
                        embedConfig={{
                            accessToken: embedTokenDetails.token!,
                            datasetBinding: {
                                datasetId: embedReportDetails.datasetId,
                            },
                            embedUrl: embedReportDetails.embedUrl!,
                            id: embedReportDetails.analyticsReportId!,
                            settings: {
                                // background: models.BackgroundType.Transparent,
                                filterPaneEnabled: true,
                                navContentPaneEnabled: true,
                            },
                            tokenType: models.TokenType.Embed,
                            type: 'report',
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    'rendered',
                                    function () {
                                        setIsLoading(false);
                                    },
                                ],
                                [
                                    'error',
                                    function (event) {
                                        setErrorMesssage(JSON.stringify(event!.detail));
                                        setIsLoading(false);
                                    },
                                ],
                            ])
                        }
                    />
                </div>
            );
        }

        return null;
    }, [embedAnalyticsReportResponse, errorMessage, isLoading]);

    const renderError: () => JSX.Element | null = useCallback(() => (errorMessage ? <p>Error: {errorMessage}</p> : null), [errorMessage]);

    const renderSpinner: () => JSX.Element | null = useCallback(
        () =>
            isLoading ? (
                <LoadingSpinner data-testid='loadingSpinner' id='loadingSpinner' label={t('loadingPleaseWait').toString()} />
            ) : null,
        [isLoading]
    );

    if (isLoading === undefined) {
        setIsLoading(true);
        embedAnalyticsReport(
            {
                data: {
                    analyticsReportId: '585d4f77-4a39-4c7f-8b8f-05d48cad68eb',
                },
            },
            {
                onSuccess: (data: IEmbedAnalyticsReportResponse) => {
                    setEmbedAnalyticsReportResponse(data);
                },
                onError: (error: ErrorType<ProblemDetails>) => {
                    setErrorMesssage(JSON.stringify(error));
                    setIsLoading(false);
                },
            }
        );
    }

    return (
        <div>
            {renderSpinner()}
            {renderError()}
            {renderAnalyticsReport()}
        </div>
    );
};

export default AnalyticsReport;
