import { mergeStyles } from '@fluentui/react';
import { Combobox, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IWhitePaperColumn } from '../../../model';

interface IPdfViewerToolbarProps {
    returnKey: number | undefined;
    reportColumns: IWhitePaperColumn[];
}

const PdfViewerToolbar: React.FC<IPdfViewerToolbarProps> = ({ returnKey, reportColumns }) => {
    const { t } = useTranslation();
    const { loadPdfPage, tocData, currentPage } = useFormPdfView();

    const selectedEntityId = currentPage?.entity?.id || reportColumns?.[0]?.columnId;
    const defaultColumn: IWhitePaperColumn | undefined = reportColumns.find((column) => column.columnId === selectedEntityId);
    const defaultComboboxSelectedOptions = defaultColumn?.columnId ? [defaultColumn.columnId.toString()] : [];
    const defaultComboboxValue = defaultColumn?.description ?? '';

    const pdfToolbarClass = mergeStyles({
        width: '100%',
        height: '56px',
        padding: '12px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid lightgray',
        marginBottom: 1,
        gap: '0.8rem',
        '& > label': {
            fontWeight: 600,
        },
    });

    const selectEntityForForm = (_: SelectionEvents, data: OptionOnSelectData) => {
        const value = data.optionValue;
        if (value) {
            const selectedEntity = reportColumns.find((column) => column.columnId === parseInt(value));
            const pages = tocData?.folders?.find((folder) => folder.id === currentPage.parentId)?.pages || [];

            if (returnKey && selectedEntity?.columnId !== undefined && selectedEntity.columnIndex !== undefined) {
                const page =
                    currentPage.pageId && currentPage.pageName
                        ? { id: currentPage.pageId, name: currentPage.pageName, uniqueId: `${currentPage.pageId}${currentPage.pageName}` }
                        : tocData?.defaultPage;
                if (page) {
                    loadPdfPage(pages, currentPage.parentId || '', `${currentPage.pageId}${currentPage.pageName}`, {
                        id: selectedEntity.columnId,
                        index: selectedEntity.columnIndex,
                    });
                }
            }
        }
    };

    return (
        <div className={pdfToolbarClass}>
            {reportColumns.length > 1 && (
                <>
                    <label>{t('entity')}</label>
                    <Combobox
                        onOptionSelect={selectEntityForForm}
                        selectedOptions={defaultComboboxSelectedOptions}
                        value={defaultComboboxValue}
                        listbox={{
                            style: { maxHeight: 300, overflowY: 'auto' },
                        }}
                    >
                        {reportColumns.map((column) => (
                            <Option
                                key={`${column.columnId}${column.columnIndex}`}
                                value={column.columnId?.toString()}
                                text={column.description ?? ''}
                            >
                                {column.description}
                            </Option>
                        ))}
                    </Combobox>
                </>
            )}
        </div>
    );
};

export default PdfViewerToolbar;
