import React from 'react';
import { useTranslation } from 'react-i18next';
import IconTabButton from '../../common/IconTabButton';

interface ActionBarButtonsProps {
    isReportActive: boolean;
    handleOpenPathToPortal: () => void;
    navigateToReport: () => void;
}

const ActionBarButtons: React.FC<ActionBarButtonsProps> = ({ isReportActive, handleOpenPathToPortal, navigateToReport }) => {
    const { t } = useTranslation();

    return (
        <>
            <IconTabButton
                label={t('report').toString()}
                iconName='DocumentBulletList20Regular'
                activeIconName='DocumentBulletList20Filled'
                onButtonClick={() => {
                    navigateToReport();
                }}
                key='reportTabButton'
                isActive={isReportActive}
            />

            <IconTabButton
                label={t('form').toString()}
                iconName='Form20Regular'
                activeIconName='Form20Filled'
                onButtonClick={() => {
                    handleOpenPathToPortal();
                }}
                key='viewFormTabButton'
                isActive={!isReportActive}
            />
        </>
    );
};

export default ActionBarButtons;
