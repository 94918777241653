import { MessageBarType } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsFeatureEnabled } from '../api/features/features';
import { useGetFormTOC, useViewerFormPDF } from '../api/report/report';
import { useFeedbackMessages } from '../hooks/useFeedbackMessages';
import { FormFolder, FormPage, FormTOC, ReviewFormDataRequest } from '../model';
import { getFormPagesAndParentId } from '../utils/PdfViewerUtils';
import { convertBlobToBase64 } from '../utils/ReportUtils';
import { VIEW_REPORT_URL } from '../utils/Urls.constant';

interface SelectedEntity {
    id: number;
    index: number;
}

export interface IFormPdfViewerContextProps {
    showPdf: boolean;
    tocData: FormTreeItem | null | undefined;
    pdfData: string | null;
    isLoading: boolean;
    loadPdfPage: (pages: FormPage[], parentId: string, pageUniqueId: string, entity?: SelectedEntity | undefined) => void;
    loadTocData: () => void;
    openForm: () => void;
    navigateToReport: () => void;
    clearFormData: () => void;
    currentPage: CurrentPageState;
    onPageChange: (page: any) => any;
}

export const FormPdfViewContext = createContext<IFormPdfViewerContextProps | null>(null);

interface IFormPdfProviderProps {
    children: any;
    returnKey: number;
    pageId?: string | undefined;
    pageName?: string | undefined;
    value?: IFormPdfViewerContextProps;
}
interface FormTreePage extends FormPage {
    selected?: boolean;
}

export interface FormTreeFolder extends FormFolder {
    expanded?: boolean;
    pages?: FormTreePage[] | null | undefined;
}
interface FormTreeItem extends FormTOC {
    folders?: FormTreeFolder[] | null | undefined;
}
export interface CurrentPageState {
    parentId?: string | null;
    pageIndex?: number | null;
    pageId?: string | null;
    pageName?: string | null;
    entity?: SelectedEntity | undefined;
}

export const getSelectedEntityFromPath = (searchParams: URLSearchParams): SelectedEntity | undefined => {
    const id = searchParams.get('entity');
    const index = searchParams.get('index');

    if (id !== null && index !== null) {
        return {
            id: parseInt(id),
            index: parseInt(index),
        };
    }
    return undefined;
};

export function FormPdfViewProvider({ children, returnKey, pageId, pageName, value }: IFormPdfProviderProps) {
    const [showPdf, setShowPdf] = useState(pageId && pageName ? true : false);
    const [pdfData, setPdfData] = useState<string | null>(null);
    const [tocData, setTocData] = useState<FormTreeItem | null>(null);
    const [currentPage, setCurrentPage] = useState<CurrentPageState>({});
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { data: isViewFormEnabled } = useIsFeatureEnabled({ featureFlagName: 'ViewFormEnabled' });

    const { t } = useTranslation();
    const { displayFeedbackMessage } = useFeedbackMessages();

    const { mutateAsync: viewFormPdfMutation, isLoading: isLoadingPdf } = useViewerFormPDF({
        mutation: {
            onSuccess: (data) => {
                convertBlobToBase64(data)
                    .then((data: any) => {
                        setPdfData(data);
                    })
                    .catch(() => {
                        handleError();
                    });
            },
            onError: () => {
                handleError();
            },
        },
    });

    const handleError = () => {
        displayFeedbackMessage({
            type: MessageBarType.error,
            message: t('pdfDisplayError'),
        });
        navigateToReport();
    };

    const clearFormData = () => {
        setShowPdf(false);
        setPdfData(null);
        setTocData(null);
        setCurrentPage({});
    };

    useEffect(() => {
        const formRouteRegex = new RegExp(`^${VIEW_REPORT_URL}\\d+/forms/?(.*)$`);
        const isFormRoute = formRouteRegex.test(location.pathname);

        if (isFormRoute) {
            setShowPdf(true);
        } else {
            setShowPdf(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (currentPage.pageId && currentPage.pageName && showPdf) {
            navigateToPage(currentPage.pageId, currentPage.pageName, currentPage.entity);
        }
    }, [currentPage, showPdf]);

    const navigateToPage = (pageId: string | undefined, pageName: string | null | undefined, entity?: SelectedEntity | undefined) => {
        let selectedEntity = entity?.id ? entity : currentPage.entity;

        if (selectedEntity?.id) {
            searchParams.set('entity', selectedEntity.id.toString());
            searchParams.set('index', selectedEntity.index?.toString());
        }

        navigate(
            { pathname: `${VIEW_REPORT_URL}${returnKey}/forms/${pageId}/${pageName}`, search: searchParams.toString() },
            { replace: true }
        );
    };

    const openForm = () => {
        if (currentPage?.pageId && currentPage.pageName) {
            navigateToPage(currentPage.pageId, currentPage.pageName, currentPage.entity);
        } else {
            navigate(`${VIEW_REPORT_URL}${returnKey}/forms`);
        }
    };

    const navigateToReport = () => {
        navigate(`${VIEW_REPORT_URL}${returnKey}`);
    };

    const { mutateAsync: getFormTOC, isLoading: isLoadingToc } = useGetFormTOC({
        mutation: {
            onSuccess: (data, request) => {
                const page = pageId && pageName ? { id: pageId, name: pageName, uniqueId: `${pageId}${pageName}` } : data.defaultPage;
                const entity = getSelectedEntityFromPath(searchParams);

                updateCurrentPageOnTree(data, page?.uniqueId, entity);

                if (isViewFormEnabled) {
                    const returnKey = request.data.taxReturnItemTOCKey;

                    if ((data.defaultPage || (pageId && pageName)) && returnKey) {
                        const { formPages } = getFormPagesAndParentId(data, pageId || data.defaultPage?.id);

                        viewFormPdfMutation({
                            data: getFormRequestData(formPages, entity),
                        });
                    }
                }
            },
        },
    });

    const clearCurrentPageOnTree = (toc: FormTreeItem) => {
        toc.folders?.forEach((folder) => {
            folder.expanded = false;
            folder?.pages?.forEach((page) => {
                page.selected = false;
            });
        });
    };

    const updateCurrentPage = (page: FormPage, folder: FormFolder, entity?: SelectedEntity) => {
        const pathEntity = getSelectedEntityFromPath(searchParams);

        const updatedPage = {
            ...currentPage,
            pageIndex: page.index || 0,
            parentId: folder.id,
            pageId: page.id,
            pageName: page.name,
        };

        if (entity || pathEntity) updatedPage.entity = entity || pathEntity;

        setCurrentPage(updatedPage);
    };

    const updateCurrentPageOnTree = (
        tocTreeItem: FormTreeItem | null,
        uniqueId: string | null | undefined,
        entity?: SelectedEntity | undefined
    ) => {
        if (tocTreeItem) {
            const toc: FormTreeItem = cloneDeep(tocTreeItem);

            clearCurrentPageOnTree(toc);

            if (uniqueId) {
                const folder = toc?.folders?.find((folder) => folder.pages?.some((page) => page.uniqueId === uniqueId));

                if (folder) {
                    const page = folder.pages?.find((page) => page.uniqueId === uniqueId);

                    if (page) {
                        page.selected = true;
                        folder.expanded = true;
                        updateCurrentPage(page, folder, entity);
                    }
                }
            }

            setTocData(toc);
        }
    };

    const getFormRequestData = (formPages: any[], entity?: SelectedEntity) => {
        const requestData: ReviewFormDataRequest = {
            returnKey: returnKey,
            formPageKeys: formPages,
        };

        if (entity) {
            requestData.columnId = entity.id;
            requestData.columnIndex = entity.index;
        }

        return requestData;
    };

    const loadPdfPage = (pages: FormPage[], parentId: string, pageUniqueId: string, entity?: SelectedEntity | undefined) => {
        entity = entity || currentPage.entity;

        updateCurrentPageOnTree(tocData, pageUniqueId, entity);

        const { index, id, name } = pages.find((page) => page.uniqueId === pageUniqueId)!;

        if (parentId === currentPage.parentId && entity === currentPage.entity) {
            setCurrentPage({ ...currentPage, pageIndex: index || 0, pageId: id, pageName: name });
            return;
        }

        const formPages = pages.map((page) => {
            return {
                formPageId: page.id,
                formPageName: page.name,
            };
        });

        viewFormPdfMutation({
            data: getFormRequestData(formPages, entity),
        });
    };

    const loadTocData = () => {
        getFormTOC({ data: { taxReturnItemTOCKey: returnKey } });
    };

    const onPageChange = (page: any) => {
        const selectedPage: any = tocData?.folders?.find((folder) => folder.id === currentPage.parentId)?.pages?.[
            page.currentPageNumber - 1
        ];

        if (selectedPage) {
            updateCurrentPageOnTree(tocData, selectedPage.uniqueId, currentPage.entity);
        }
    };

    return (
        <FormPdfViewContext.Provider
            value={
                value ?? {
                    showPdf,
                    pdfData,
                    tocData,
                    isLoading: isLoadingPdf || isLoadingToc,
                    loadTocData,
                    loadPdfPage,
                    openForm,
                    navigateToReport,
                    clearFormData,

                    currentPage,
                    onPageChange,
                }
            }
        >
            {children}
        </FormPdfViewContext.Provider>
    );
}

export default FormPdfViewProvider;
