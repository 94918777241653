import { useCorptaxTheme } from '@corptax/react-components-common';
import { IContextualMenuItem, IContextualMenuProps, Separator } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { EmitType } from '@syncfusion/ej2-base';
import { ColumnModel, GridComponent, IIndex, RowSelectEventArgs } from '@syncfusion/ej2-react-grids';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFeatureEnabled } from '../../../api/features/features';
import {
    useGetComparedWhitePaperReport,
    useGetTaxReturnById,
    useGetWhitePaperReport,
    useGetWhitePaperReportPreviousVersions,
} from '../../../api/report/report';
import { CommentsUIStateProvider } from '../../../contexts/commentsUIStateContext';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IFilterItem } from '../../../data-types/filterItem';
import { ICurrentCellEventArgs } from '../../../data-types/ICurrentCellEventArgs';
import { IRowInfo } from '../../../data-types/IRowInfo';
import { ISelectableGrid } from '../../../data-types/ISelectableGrid';
import { createSelectedWhitePaperCell } from '../../../data-types/selectedWhitePaperCell';
import { ContextMenuItemDisplayState } from '../../../hooks/useCommonIndividualReportTableContextMenu';
import { useCommonIndividualReportTableHeaderStyles } from '../../../hooks/useCommonIndividualReportTableHeaderStyles';
import { useDrillDown } from '../../../hooks/useDrillDown';
import { useFunctionalAccess } from '../../../hooks/useFunctionalAccess';
import { useGridScroll } from '../../../hooks/useGridScroll';
import { useGridState } from '../../../hooks/useGridState';
import { usePerformanceMetricsForTable } from '../../../hooks/usePerformanceMetricsForTable';
import { useSearchTable } from '../../../hooks/useSearchTable';
import { useSelectedEntities } from '../../../hooks/useSelectedEntities';
import { useTaxReturnCalculationState } from '../../../hooks/useTaxReturnCalculationState';
import { useVarianceNavigation } from '../../../hooks/useVarianceNavigation';
import useVarianceThreshold from '../../../hooks/useVarianceThreshold';
import {
    IWhitePaperColumn,
    IWhitePaperMappedRowDictionary,
    IWhitePaperReport,
    IWhitePaperReportChanges,
    IWhitePaperRow,
    ProblemDetails,
    Report,
    WhitePaperReportVersion,
} from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';
import { isResponseArrayValid } from '../../../utils/ApiClientUtils';
import { generateColumns, getColumnHeaderTemplate } from '../../../utils/ColumnUtils';
import { useDataManager } from '../../../utils/DataManagerUtils';
import { getDateStringAsLocaleString } from '../../../utils/DateUtils';
import { isDevelopment } from '../../../utils/EnvironmentUtils';
import { getEntitiesFilterForColumns, getSectionsFilterItem } from '../../../utils/FilterUtils';
import {
    getDynamicFieldNameParts,
    getReportVersionLabelText,
    getVisibleEntityColumnNames,
    isDrillDownContextMenuAllowed,
    isMultiColumnReport,
} from '../../../utils/ReportUtils';
import {
    compareValueFieldNameSuffix,
    currentValueFieldNameSuffix,
    TOTAL_ROW_NUMBER,
    varianceValueFieldNameSuffix,
} from '../../../utils/Table.constants';
import { clickOnVarianceContainedByCell, getColumnNameFromCurrentComparedColumnName } from '../../../utils/TableUtils';
import GridSearchBar from '../../common/GridSearchBar';
import CustomIconButton from '../../common/IconButton';
import Modal from '../../common/Modal';
import EnterAmountsDialog, { IEnterAmountsDialogProps } from '../../enter-amounts-dialog/EnterAmountsDialog';
import FilterButton from '../../filters/filter-button/FilterButton';
import { CommentsPanel, CommentsPanelLocationType } from '../../sidebar/CommentsPanel';
import ReportDrillDown from '../../sidebar/ReportDrillDown';
import ActionBarButtons from '../action-buttons/actionBarButtons';
import CalculateButton from '../action-buttons/calculateButton';
import CommonIndividualReportTable, {
    descriptionTemplate,
    ICommonIndividualReportTableContextMenuItemProps,
    ICommonIndividualReportTableContextMenuProps,
} from '../common-individual-report-table/CommonIndividualReportTable';
import ScrollToSectionDropdown from '../common-individual-report-table/ScrollToSectionDropdown';
import VarianceGoToNavigation from '../common-individual-report-table/VarianceGoToNavigation';
import VarianceThresholdButton from '../common-individual-report-table/VarianceThresholdButton';

export interface ICompareReportsTableProps {
    navigateToReport: (reportId: number) => void;
    navigateToCompareReport: (versionId: string, selectedEntities: string[]) => void;
    reportId: number;
    versionId: string;
    errorModalOnClose: () => void;
}
const CompareReportsTable: FC<ICompareReportsTableProps> = (props: ICompareReportsTableProps) => {
    const { startTrackingScrollEvent, trackRenderFinished } = usePerformanceMetricsForTable('Compare return');
    const clickableColumnStartIndex: number = 2;

    const { t } = useTranslation();
    const { setTraceCalculationData } = useDrillDown();
    const selectableGridRef = useRef<ISelectableGrid | null>(null);
    const gridComponentRef = useRef<GridComponent | null>(null);
    const { selectedEntities, setSelectedEntitiesSearchParams, getFilteredEntities } = useSelectedEntities();
    const {
        varianceThresholdCookieValue,
        varianceThresholdTypeCookieValue: varianceThresholdType,
        varianceThresholdDisplayFormatCookieValue: varianceThresholdDisplayFormat,
        showOnlyExceedingRowsCookieValue,
    } = useVarianceThreshold(props.reportId.toString());
    const isValidThresholdCookie = varianceThresholdCookieValue !== undefined && varianceThresholdCookieValue >= 0;

    const { isReportExecuting: isInTaxCalculationReportList, rerunCalculationReport, registerDependency } = useTaxReturnCalculationState();
    const [currentCellArgs, setCurrentCellArgs] = useState<ICurrentCellEventArgs<IWhitePaperRow> | null>(null);
    const {
        data: currentWhitePaperReport,
        isError: isComparedWhitePaperReportError,
        isFetching: isFetchingComparedReport,
        queryKey: queryKeyReport,
        error: comparedReportError,
    } = useGetComparedWhitePaperReport<IWhitePaperReport>({
        ReportId: props.reportId,
        VersionId: props.versionId,
        VarianceThreshold: isValidThresholdCookie ? varianceThresholdCookieValue : null,
        VarianceThresholdType: varianceThresholdType,
        VarianceThresholdDisplayFormat: varianceThresholdDisplayFormat,
        SelectedEntities: selectedEntities,
    });

    const {
        data: currentReportInformation,
        isError: isTaxReturnError,
        isFetching: isFetchingTaxReturn,
    } = useGetTaxReturnById<Report>(props.reportId);

    const {
        data: report,
        isError: isErrorReportMap,
        isFetching: isFetchingReportMap,
    } = useGetWhitePaperReport<IWhitePaperReport>({ ReportId: props.reportId });

    const { data: availableVersions, queryKey: previousVersionsKey } = useGetWhitePaperReportPreviousVersions({
        ReportId: props.reportId,
    });

    const { data: isViewFormEnabled } = useIsFeatureEnabled({ featureFlagName: 'ViewFormEnabled' });

    const { dataManager, contentLength } = useDataManager(
        currentWhitePaperReport!,
        props.reportId.toString(),
        showOnlyExceedingRowsCookieValue
    );
    const isMultiColumns = isMultiColumnReport(currentWhitePaperReport);
    const [currentWhitePaperColumn, setCurrentWhitePaperColumn] = useState<IWhitePaperColumn | undefined>(undefined);
    const [isDrillDownVisible, { setTrue: showDrillDown, setFalse: hideDrillDown }] = useBoolean(false);
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
    const [commentData, setCommentData] = useState<{ rowData: IWhitePaperRow; columnName: string } | null | undefined>(undefined);

    // this is true when someone saves an override (has not been implemented yet), or when someone saves one or more amounts from the Drilldown Panel
    const [isRecalculateNeeded, setIsRecalculateNeeded] = useState<boolean>(false);
    const [changeTrackingData, setChangeTrackingData] = useState<IWhitePaperReportChanges>({});

    useEffect(() => {
        registerDependency(props.reportId, queryKeyReport);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportId]);

    const entities: IFilterItem[] = getEntitiesFilterForColumns(currentWhitePaperReport?.columns || []);
    const filteredEntities: IFilterItem[] = getFilteredEntities(entities);
    const { openForm, showPdf, tocData } = useFormPdfView();

    const getColumns = useCallback(() => {
        const rowNumberColumnModel: ColumnModel = {
            field: 'rowNumber',
            width: '100px',
            headerText: 'Row Number',
            isFrozen: isMultiColumns,
            freeze: isMultiColumns ? 'Left' : 'None',
            allowResizing: true,
            autoFit: false,
            allowEditing: false,
            isPrimaryKey: true,
            visible: isDevelopment(),
        };
        const descriptionColumnModel: ColumnModel = {
            field: 'description',
            maxWidth: '3130px',
            width: '450px',
            headerText: t('description').toString(),
            isFrozen: isMultiColumns,
            freeze: isMultiColumns ? 'Left' : 'None',
            headerTemplate: getColumnHeaderTemplate,
            template: descriptionTemplate,
            customAttributes: { class: 'white-paper-report-customizable-cell' },
            allowEditing: false,
            visible: true,
            editTemplate: (props: IWhitePaperRow) => <span style={{ paddingLeft: '23px' }}>{props.description}</span>,
        };

        return [
            rowNumberColumnModel,
            descriptionColumnModel,
            ...generateColumns(
                currentWhitePaperReport,
                t,
                filteredEntities,
                currentValueFieldNameSuffix,
                compareValueFieldNameSuffix,
                varianceValueFieldNameSuffix,
                getDateStringAsLocaleString
            ),
        ];
    }, [currentWhitePaperReport, t, filteredEntities, getColumnHeaderTemplate, descriptionTemplate, isMultiColumns]);

    const getColumnIdFromFieldName = useCallback((fieldName: string): string => {
        if (!fieldName.includes('.')) {
            return fieldName;
        }

        const { entityCode } = getDynamicFieldNameParts(fieldName);
        return entityCode;
    }, []);

    const isMenuEnabledForTableColumn = (cellIndex: number): boolean => {
        return cellIndex >= clickableColumnStartIndex;
    };

    const isDrillDownAllowed = (cellIndex?: IIndex) => {
        if (cellIndex && cellIndex.cellIndex) {
            return isMenuEnabledForTableColumn(cellIndex.cellIndex);
        }
        return false;
    };
    const [enterAmountsDialogProps, setEnterAmountsDialogProps] = useState<IEnterAmountsDialogProps | null>(null);
    const openEnterAmountsIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            const currentColumnName = getColumnNameFromCurrentComparedColumnName(eventArgs);
            const currentColumn = currentWhitePaperReport?.columns?.find((column) => column.fieldName === currentColumnName);

            setEnterAmountsDialogProps({
                //buttonTrigger: eventArgs as HTMLElement,
                rowDescription: `Row ${eventArgs.data.description}, ${currentColumn?.entityCode} ${currentColumn?.entityName} `,
                columnId: currentColumn?.originalColumnId,
                drillDownRowNumber: Number.parseInt(eventArgs.data.displayRowNumber!),
                onClose: () => setEnterAmountsDialogProps(null),
            } as unknown as IEnterAmountsDialogProps);
        }
    };
    const openDrillDownIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            const currentColumnName = getColumnNameFromCurrentComparedColumnName(eventArgs);
            if (currentColumnName) {
                const currentColumn = currentWhitePaperReport?.columns?.find((column) => column.fieldName === currentColumnName);
                if (currentColumn) {
                    setCurrentWhitePaperColumn(currentColumn);
                    setTraceCalculationData([
                        { displayRowNumber: TOTAL_ROW_NUMBER },
                        createSelectedWhitePaperCell(eventArgs.data, currentColumnName),
                    ]);
                    showDrillDown();
                }
            }
        }
    };

    const closeDrillDown = (changeTrackingData: { amountsChangedOnServer: boolean; reportChanges: IWhitePaperReportChanges }) => {
        const { amountsChangedOnServer, reportChanges } = changeTrackingData;
        hideDrillDown();
        const isRecalculateNeededNow = isRecalculateNeeded || amountsChangedOnServer;
        setIsRecalculateNeeded(isRecalculateNeededNow);
        setChangeTrackingData(reportChanges);
        if (selectableGridRef.current) {
            selectableGridRef.current.focusCell(undefined as unknown as IIndex);
            if (amountsChangedOnServer) {
                selectableGridRef.current.markAsEdited(currentCellArgs?.cellIndex!);
            }
        }
    };

    const openCommentsIfAllowed = (eventArgs?: ICurrentCellEventArgs<IWhitePaperRow> | null) => {
        if (eventArgs) {
            if (isDrillDownAllowed(eventArgs.cellIndex)) {
                setCommentData({ rowData: eventArgs.data, columnName: getColumnIdFromFieldName(eventArgs.fieldName) });
                setIsCommentsPanelOpen(true);
            }
        }
    };

    useEffect(() => {
        setIsRecalculateNeeded(currentWhitePaperReport?.reCalculationRequired ?? false);
        setChangeTrackingData(currentWhitePaperReport?.changeTrackingData ?? {});
    }, [currentWhitePaperReport]);

    useEffect(() => {
        registerDependency(props.reportId, previousVersionsKey);
    }, [previousVersionsKey]);
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const getDropdownItems = (): IContextualMenuItem[] => {
        if (!isResponseArrayValid(availableVersions)) {
            return [];
        }

        return (
            availableVersions?.map((version: WhitePaperReportVersion) => {
                return {
                    key: version.versionId ?? '',
                    name: getReportVersionLabelText(version),
                    onClick: () => {
                        props.navigateToCompareReport(version.versionId ?? '', selectedEntities);
                        handleChangeCompareDate();
                    },
                    itemProps: {
                        styles: {
                            label: {
                                color: version.versionId === (props.versionId ?? '') ? customPalette.themePrimary : customPalette.blueDark,
                                fontSize: '13px',
                                fontWeight: '600',
                                padding: '0px 24px',
                            },
                        },
                    },
                };
            }) ?? []
        );
    };

    const isFetching = isFetchingTaxReturn || isFetchingComparedReport || isFetchingReportMap;
    const currentReportIsCalculating = isInTaxCalculationReportList(props.reportId) || false;

    const handleOpenPathToPortal = () => {
        if (isViewFormEnabled) {
            openForm();
        } else {
            currentReportInformation?.pathToPortal && window.open(currentReportInformation?.pathToPortal, '_blank');
        }
    };

    const { isSearchValueApplied, clearSearch } = useSearchTable();

    const { grid, isFilterApplied } = useGridState();

    const {
        setIsVarianceNavigating,
        setCurrentVarianceIndex,
        horizontallyScrollToVariance,
        isDisabledVarianceGoToNavigation,
        setIsDisabledVarianceGoToNavigation,
    } = useVarianceNavigation();

    const navigateToReport = () => {
        setCurrentVarianceIndex(0);
        clearSearch(grid);
        props.navigateToReport(props.reportId);
    };
    const handleChangeCompareDate = () => {
        clearSearch(grid);
        setCurrentVarianceIndex(0);
        setIsDisabledVarianceGoToNavigation(false);
    };

    const getCurrentCompareReportName = (): string => {
        return getDropdownItems().find((r) => r.key === (props.versionId ?? ''))?.name || '';
    };

    const rerunCalculation = async () => {
        rerunCalculationReport(props.reportId, currentReportInformation?.taxReturnItemId ?? '', undefined, (data: any) => {
            setIsRecalculateNeeded(false);
        });
    };

    const compareHistoryButtonId = `compareHistoryButton`;
    const reportVersionsMenuProps: IContextualMenuProps = {
        items: getDropdownItems(),
        title: t('datesRun').toString(),
        styles: {
            title: {
                fontSize: '10px',
                lineHeight: '22px',
                fontWeight: '600',
                backgroundColor: 'unset',
                padding: '20px 20px 0px',
            },
        },
        ariaLabel: t('compareHistory').toString(),
    };

    const selectedVersion = availableVersions
        ? availableVersions.find((version) => version.versionId === (props.versionId ?? ''))
        : undefined;
    const getTitleForReport = (reportInformation: Report | undefined, version: WhitePaperReportVersion | undefined): string => {
        if (version && version.dateTime) {
            return `${t('titleCompare')} ${reportInformation?.name} ${t('titleCompareTo')} ${getDateStringAsLocaleString(
                version.dateTime
            )}`;
        }

        return reportInformation?.name || t('taxReturns');
    };
    document.title = getTitleForReport(currentReportInformation, selectedVersion);
    const { separatorStyles } = useCommonIndividualReportTableHeaderStyles();

    const getCompareButton: () => JSX.Element = () => {
        if (!availableVersions?.length) {
            return <Fragment key='compareButtonWrapper'></Fragment>;
        }

        return (
            <Fragment key='compareButtonWrapper'>
                <Separator className={separatorStyles} vertical />
                <CustomIconButton
                    id={compareHistoryButtonId}
                    label={getCurrentCompareReportName()}
                    iconName='HistoryRegular'
                    menuProps={reportVersionsMenuProps}
                    disabled={isFetching || currentReportIsCalculating}
                    isActive={true}
                    showCancelButton={true}
                    cancelButtonAriaLabel={t('closeCompareMode').toString()}
                    handleCancelButtonClick={navigateToReport}
                ></CustomIconButton>
                <VarianceThresholdButton disabled={isFetching || isFilterApplied || currentReportIsCalculating} reportId={props.reportId} />
                <VarianceGoToNavigation
                    variancePoints={currentWhitePaperReport?.variances || []}
                    isDisabled={isFetching || isDisabledVarianceGoToNavigation || isSearchValueApplied || currentReportIsCalculating}
                    showOnlyExceedingRows={showOnlyExceedingRowsCookieValue}
                />
            </Fragment>
        );
    };

    const compareButton: JSX.Element = getCompareButton();
    const { currentColumnIndex, currentRowIndex, programmaticallyScrollToRow } = useGridScroll();
    const onRowSelected: EmitType<RowSelectEventArgs> = () => {
        if (currentColumnIndex.current) {
            if (isMultiColumns) {
                horizontallyScrollToVariance(grid.current, currentColumnIndex.current);
            }

            clickOnVarianceContainedByCell(grid.current, {
                rowIndex: currentRowIndex.current ?? undefined,
                cellIndex: currentColumnIndex.current ?? undefined,
            });
            //currentColumnIndex.current = 0;
        }
        setIsVarianceNavigating(false);
    };

    const sections: IFilterItem[] = useMemo(
        () => getSectionsFilterItem(currentWhitePaperReport?.rows || [], tocData?.statements || []),
        [currentWhitePaperReport, tocData]
    );
    const onSectionsScrollToChange = (filteredItems: IFilterItem[]) => {
        if (grid && grid.current) {
            const rowId = parseInt(filteredItems[0].key, 10);
            const rowIndex = currentWhitePaperReport?.rows?.findIndex((row) => row.rowNumber === rowId);
            programmaticallyScrollToRow(grid.current, { rowIndex: rowIndex });
        }
    };
    const [searchEntityValue, setSearchEntityValue] = useState<string>('');
    const updateVisibleColumns = (filteredItems: IFilterItem[]) => {
        if (grid && grid.current && currentWhitePaperReport) {
            getVisibleEntityColumnNames(filteredItems, currentWhitePaperReport?.columns ?? []);
        }
    };
    const onFilterChange = (filteredItems: IFilterItem[]) => {
        setCurrentVarianceIndex(0);
        setSelectedEntitiesSearchParams(filteredItems);
        updateVisibleColumns(filteredItems);
    };
    const handlerSetCheckedItems = (filterItems: IFilterItem[]) => {
        onFilterChange(filterItems);
        clearSearch(gridComponentRef);
    };

    const columns = getColumns();

    const renderFinished = () => {
        trackRenderFinished({
            rowCount: contentLength,
            columnCount: columns.length,
            visibleColumnCount: columns.filter((column) => column.visible).length,
            reportId: props.reportId,
            isMultiColumn: isMultiColumns,
        });
    };

    const calculateButton = CalculateButton({
        onClick: rerunCalculation,
        disabled: isFetching || currentReportIsCalculating || showPdf,
    });

    const filterGroupItems: React.ReactNode[] = [
        <ScrollToSectionDropdown
            sections={sections}
            isFiltered={false}
            filterName={''}
            label='goToSection'
            showSingleSelect={true}
            onSectionChange={onSectionsScrollToChange}
            isDisabled={showOnlyExceedingRowsCookieValue}
            key='scrollToSectionDropdown'
        />,
        isMultiColumns ? (
            <FilterButton
                data-testid='filterButton'
                filterName='entityName'
                items={entities}
                label='entity'
                checkedItems={filteredEntities}
                setCheckedItems={handlerSetCheckedItems}
                searchValue={searchEntityValue}
                setSearchValue={setSearchEntityValue}
                noElementsFoundtext={t('noEntitiesFound')}
                key='filterEntityButton'
            />
        ) : null,
        <GridSearchBar searchBoxPlaceholderText={t('search')} key='gridSearchBar' />,
        compareButton,
    ];

    const modalBodyContent = (problemDetails: ProblemDetails) => (
        <>
            <p>{t('compareReportsErrorModalBody')}</p>
            <p>{`${t('compareReportsErrorModalCorrelationIdLabel')} ${problemDetails?.traceId}`}</p>
        </>
    );
    const actionBarButtons = ActionBarButtons({
        isReportActive: !showPdf,
        handleOpenPathToPortal,
        navigateToReport,
    });
    const { data: isEnterAmountsEnabled } = useIsFeatureEnabled({ featureFlagName: 'EnterAmountsModalEnabled' });
    const onEnterAmountsClosedCallback: (amountsChangedOnServer: boolean, updatedChangeTrackingData: IWhitePaperReportChanges) => void = (
        amountsChangedOnServer: boolean,
        updatedChangeTrackingData: IWhitePaperReportChanges
    ) => {
        setEnterAmountsDialogProps(null);
        setIsRecalculateNeeded(isRecalculateNeeded || amountsChangedOnServer);
        setChangeTrackingData(updatedChangeTrackingData);
        if (selectableGridRef.current) {
            selectableGridRef.current.focusCell(undefined as unknown as IIndex);
            if (changeTrackingData !== updatedChangeTrackingData) {
                selectableGridRef.current.markAsEdited(currentCellArgs?.cellIndex!);
            }
        }
    };

    const { functionalAccess } = useFunctionalAccess();
    return (
        <>
            <CommentsUIStateProvider>
                <CommentsPanel
                    locationType={CommentsPanelLocationType.ReportField}
                    reportId={props.reportId.toString()}
                    reportName={currentReportInformation?.name ?? undefined}
                    reportRowNumber={commentData?.rowData?.rowNumber}
                    reportRowDescription={commentData?.rowData?.description ?? undefined}
                    reportColumnName={commentData?.columnName ?? undefined}
                    isOpen={isCommentsPanelOpen}
                    onClose={() => setIsCommentsPanelOpen(false)}
                ></CommentsPanel>
            </CommentsUIStateProvider>
            <CommonIndividualReportTable
                key={props.reportId}
                columns={columns}
                currentReport={currentWhitePaperReport}
                isError={isTaxReturnError || isComparedWhitePaperReportError || isErrorReportMap}
                isTableDataReadyToDisplay={!isFetching}
                componentRef={selectableGridRef}
                dataManager={dataManager}
                currentCellArgs={currentCellArgs}
                setCurrentCellArgs={setCurrentCellArgs}
                isRecalculateNeeded={isRecalculateNeeded}
                setIsRecalculateNeeded={setIsRecalculateNeeded}
                onRenderFinished={renderFinished}
                onScrollingStarted={startTrackingScrollEvent}
                contextMenuProps={
                    {
                        enterAmounts: {
                            getVisibilityState: (rowData: IRowInfo<IWhitePaperRow>) => {
                                if (
                                    isDrillDownContextMenuAllowed(rowData, isMultiColumns, currentWhitePaperReport, isDrillDownAllowed) &&
                                    isEnterAmountsEnabled
                                ) {
                                    if (functionalAccess?.amountDataEntryAccessEnabled) {
                                        return ContextMenuItemDisplayState.Enabled;
                                    }
                                    return ContextMenuItemDisplayState.Disabled;
                                }
                                return ContextMenuItemDisplayState.Invisible;
                            },
                            onMenuItemClick: openEnterAmountsIfAllowed,
                        } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                        drillDown: {
                            getVisibilityState: (rowData: IRowInfo<IWhitePaperRow>) => {
                                if (isDrillDownContextMenuAllowed(rowData, isMultiColumns, currentWhitePaperReport, isDrillDownAllowed)) {
                                    if (functionalAccess?.amountDataEntryAccessEnabled) {
                                        return ContextMenuItemDisplayState.Enabled;
                                    }
                                    return ContextMenuItemDisplayState.Disabled;
                                }
                                return ContextMenuItemDisplayState.Invisible;
                            },
                            onMenuItemClick: openDrillDownIfAllowed,
                        } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                        comments: {
                            getVisibilityState: (rowData: IRowInfo<IWhitePaperRow>) => {
                                const cellIndex = { ...rowData } as IIndex;
                                if (isDrillDownAllowed(cellIndex)) {
                                    return ContextMenuItemDisplayState.Enabled;
                                }
                                return ContextMenuItemDisplayState.Invisible;
                            },
                            onMenuItemClick: openCommentsIfAllowed,
                        } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                        editCell: {
                            getVisibilityState: () => ContextMenuItemDisplayState.Invisible,
                        } as ICommonIndividualReportTableContextMenuItemProps<IWhitePaperRow>,
                    } as ICommonIndividualReportTableContextMenuProps
                }
                reportId={props.reportId}
                versionId={props.versionId}
                calculateButton={calculateButton}
                actionBar={actionBarButtons}
                filterGroupItems={filterGroupItems}
                onRowSelected={onRowSelected}
                clearAllFilters={() => handlerSetCheckedItems([])}
                shouldDisplayCalculationOptions={false}
                loadingIndicator={{ indicatorType: 'Shimmer' }}
            />

            {isDrillDownVisible && (
                <ReportDrillDown
                    onClose={closeDrillDown}
                    report={currentReportInformation!}
                    showPanel={isDrillDownVisible}
                    whitePaperReportMap={report?.rowsMap as IWhitePaperMappedRowDictionary}
                    whitePaperReportColumn={currentWhitePaperColumn}
                    changeTrackingData={changeTrackingData}
                    isMultiColumnWhitePaperReport={isMultiColumns}
                />
            )}
            {enterAmountsDialogProps && (
                <EnterAmountsDialog
                    {...enterAmountsDialogProps}
                    case={currentReportInformation!.case!}
                    jurisdiction={currentReportInformation!.jurisdiction!}
                    period={currentReportInformation!.year!.toString()}
                    entity={currentReportInformation!.entityCode!}
                    adjustmentCode=''
                    location={currentReportInformation!.location!}
                    changeTrackingData={changeTrackingData}
                    updateChangeTrackingData={setChangeTrackingData}
                    taxReturnKey={currentReportInformation!.id!}
                    onClose={onEnterAmountsClosedCallback}
                />
            )}

            {comparedReportError && (
                <Modal
                    isOpen={true}
                    onClose={props.errorModalOnClose}
                    headerContent={<h2>{comparedReportError.response?.data.title}</h2>}
                    bodyContent={modalBodyContent(comparedReportError?.response?.data as ProblemDetails)}
                />
            )}
        </>
    );
};

export { CompareReportsTable as default };
