import { mergeStyles } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IWhitePaperColumn } from '../../../model';
import DefaultPDFViewer from './DefaultPDFViewer';
import FormTOCSidebar from './FormTOCSidebar';
import PdfViewerToolbar from './PDFViewerToolbar';

interface IPdfViewerFormProps {
    returnKey: number | undefined;
    reportColumns: IWhitePaperColumn[];
}

const PdfViewerForm: React.FC<IPdfViewerFormProps> = ({ returnKey, reportColumns }) => {
    const { loadTocData, isLoading, pdfData, tocData, showPdf, clearFormData, onPageChange } = useFormPdfView();

    const pdfContainerClass = mergeStyles({
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        display: showPdf ? 'flex' : 'none',
        height: pdfData ? '100%' : 'none',
        flexDirection: 'column',
        border: '1px solid lightgray',
        borderRadius: 5,
    });

    useEffect(() => {
        loadTocData();

        return () => clearFormData();
    }, []);

    return (
        <div className={`${pdfContainerClass}`}>
            <PdfViewerToolbar returnKey={returnKey} reportColumns={reportColumns}></PdfViewerToolbar>
            {tocData?.folders?.length && pdfData && returnKey ? <FormTOCSidebar returnKey={returnKey} /> : null}
            <DefaultPDFViewer pdfData={pdfData} isLoading={isLoading} showPdf={showPdf} onPageChange={onPageChange} />
        </div>
    );
};

export default PdfViewerForm;
