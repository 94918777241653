import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { IButtonStyles, IconButton, mergeStyles } from '@fluentui/react';
import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-treegrid';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IAppTheme } from '../../../theme/IAppTheme';
import { IconName } from '../../../utils/Icons.constants';
import Icon from '../../common/Icon';

export interface IFormTOCSidebarProps {
    returnKey: number;
}
export const TOC_SIDEBAR_WIDTH = 400;
export const CLOSED_TOC_SIDEBAR_WIDTH = 50;

const focusStyle = {
    ':focus': {
        border: '1px solid black',
    },
};

const FormTOCSidebar: React.FC<IFormTOCSidebarProps> = ({ returnKey }) => {
    const [isDrawerExpanded, setIsDrawerExpanded] = useState<boolean>(true);
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { t } = useTranslation();
    const { tocData, loadPdfPage, isLoading, showPdf, currentPage } = useFormPdfView();
    const treeViewRef: any = useRef(null);

    const handleEscPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isDrawerExpanded) {
            setIsDrawerExpanded(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscPress);
        return () => {
            window.removeEventListener('keydown', handleEscPress);
        };
    }, []);

    const scrollToCurrentPage = () => {
        if (treeViewRef.current && currentPage.pageId) {
            const node = treeViewRef.current?.element?.querySelector(`[data-uid="${currentPage.pageId}${currentPage.pageName}"]`);
            if (node) {
                node.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    const tableOfContentsClassName = mergeStyles({
        height: '100%',
    });

    const toggleDrawerButtonStyles: IButtonStyles = {
        root: {
            width: '30px',
            color: customPalette.blueLight,
            ...focusStyle,
        },
    };

    const expandedHeaderContentClass = mergeStyles({
        position: 'sticky',
        top: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
    });

    useEffect(() => {
        if (isDrawerExpanded) scrollToCurrentPage();
    }, [isDrawerExpanded, currentPage.pageId]);

    useEffect(() => {
        if (showPdf) {
            setIsDrawerExpanded(true);
        }
    }, [showPdf]);

    const collapsedContentClass = mergeStyles({ display: 'flex', flexDirection: 'column', alignItems: 'center' });

    const titleClass = mergeStyles({ marginLeft: '20px', textAlign: 'center' });

    const collapsedButtonStyles: IButtonStyles = {
        root: {
            width: '40px',
            height: '50px',
            marginBottom: 10,
            color: customPalette.blueLight,
            ...focusStyle,
        },
        icon: {
            fontSize: '20px',
        },
    };

    const formsTextClass = mergeStyles({
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        padding: '0px',
        textAlign: 'center',
        color: customPalette.neutralDark,
        fontSize: '12px',
        fontWeight: '600',
    });

    const toggleDrawerButtonIconName: IconName = isDrawerExpanded ? 'ChevronDoubleRightRegular' : 'ChevronDoubleLeftRegular';
    const toggleDrawerAriaLabel = isDrawerExpanded ? 'Collapse Panel' : 'Expand Panel';

    const fields: Object = {
        dataSource: tocData?.folders || [],
        id: 'uniqueId',
        title: 'title',
        child: 'pages',
        hasChildren: 'hasChild',
        name: 'name',
        selected: 'selected',
        expanded: 'expanded',
    };

    const rowTemplateClass = mergeStyles({
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    });

    const rowTitleClass = mergeStyles({ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 300 });

    const rowTemplate = (rowData: any) => (
        <div className={rowTemplateClass}>
            {rowData.entityName === 'FormFolder' && <Icon iconName='Form20Regular' style={{ color: customPalette.blue075A92 }} />}
            <span className={rowTitleClass}>{rowData.title}</span>
        </div>
    );

    const onSelectTreeViewNode = (args: any) => {
        const isParent = args.nodeData.parentID === null;
        const folderId = isParent ? args.nodeData.id : args.nodeData.parentID;

        const pagesReceived = tocData?.folders?.find((folder) => folder.id === folderId)?.pages || [];

        if (pagesReceived && pagesReceived.length) {
            const firstPageUniqueId = isParent ? pagesReceived[0]?.uniqueId : args.nodeData.id;
            loadPdfPage(pagesReceived, folderId, firstPageUniqueId);
        }
    };

    const TreeView = memo(() => {
        return (
            <TreeViewComponent
                disabled={isLoading}
                ref={treeViewRef}
                nodeSelected={onSelectTreeViewNode}
                nodeTemplate={rowTemplate}
                className={tableOfContentsClassName}
                fields={fields}
                key='id'
            >
                <ColumnsDirective>
                    <ColumnDirective field='title' />
                </ColumnsDirective>
            </TreeViewComponent>
        );
    });

    return (
        <SidebarComponent
            id='dockSidebar'
            data-testid='dockSidebar'
            animate={false}
            enableDock={true}
            closeOnDocumentClick
            style={{ backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1 }}
            dockSize={CLOSED_TOC_SIDEBAR_WIDTH}
            width={TOC_SIDEBAR_WIDTH}
            close={() => setIsDrawerExpanded(false)}
            open={() => setIsDrawerExpanded(true)}
            isOpen={isDrawerExpanded}
            target='.pdf-container'
            type='Over'
            position='Right'
        >
            {isDrawerExpanded ? (
                <div style={{ width: '100%' }}>
                    <div className={expandedHeaderContentClass}>
                        <h3 className={titleClass}>{t('forms')}</h3>
                        <IconButton
                            data-testid='collapse-button'
                            styles={toggleDrawerButtonStyles}
                            ariaLabel={toggleDrawerAriaLabel}
                            iconProps={{ iconName: toggleDrawerButtonIconName }}
                            onClick={(): void => {
                                setIsDrawerExpanded(!isDrawerExpanded);
                            }}
                        />
                    </div>

                    <TreeView />
                </div>
            ) : (
                <div className={collapsedContentClass}>
                    <IconButton
                        data-testid='expand-button'
                        styles={collapsedButtonStyles}
                        ariaLabel={toggleDrawerAriaLabel}
                        iconProps={{ iconName: toggleDrawerButtonIconName }}
                        onClick={(): void => {
                            setIsDrawerExpanded(!isDrawerExpanded);
                        }}
                    />
                    <span className={formsTextClass}>{t('forms')}</span>
                </div>
            )}
        </SidebarComponent>
    );
};

export default FormTOCSidebar;
